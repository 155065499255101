import { APIService } from './services/api.service';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userName: string;
  title = 'Base Template';
  constructor(
    private route: ActivatedRoute,
    private apiService: APIService
  ) {
    this.userName = "";
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['DB']) {
        this.apiService.setDb(params['DB']);
      } else {
        // TODO Implement window to display parameter error
      }
    });
  }


  logout() {
    
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { Location } from '@angular/common';

@Component({
  selector: 'app-splash-screen',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent {
  constructor(private router: Router, private location: Location) {}
  navigateToTarget(site: any) {
    if( site == 'hive'){
      this.router.navigate(['/home']);
    }
    else if( site == 'mba'){
      let path = location.host.toLowerCase();
      if ( path.includes('theia4u') ){
        window.location.href = 'http://MBA.theia4u.com';
      }
      else{  
        window.location.href = 'http://localhost:55710';
      }
      //this.router.navigateByUrl('http://localhost:55710');
    }
  }

}

<p-confirmPopup size="60vw"></p-confirmPopup>
<div class="row">
  <!--<p-button (click)="showScanDialog()" icon="pi pi-external-link" label="BarCode"></p-button>-->
  <p-dialog header="Scan BarCode" (onHide)="onClose($event)" [(visible)]="displayScan" [breakpoints]="{'960px': '75vw', '640px': '85vw', '390px': '95vw'}"
    [style]="{width: '65vw'}" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header"  style="background: rgba(0,0,0,.03) !important">
      Artist Search
      <!--<div class="row" width="100%" title="Click + Hold to drag"><img src="../../assets/drag.svg"></div>-->
    </ng-template>
    <ng-template pTemplate="content">
      <!--
      <div class="row my-2">
        <div class="col-md-2"></div>
        <div class="col-md-4">
          <button class="btn btn-default btn-lg" (click)="onScan($event)">
            <img style="width: 26px" src="../../../assets/icons8-barcode-reader-96.png"/> Scan Barcode
          </button>
        </div>
        <div class="col-md-4">
          <button class="btn btn-default btn-lg" (click)="onKeyboard($event)">
            <fa-icon class="fa-xl" title="keyboard" [icon]="faKeyboard"></fa-icon> Enter Barcode
          </button>
        </div>
        <div class="col-md-2"></div>
      </div>
      -->
      <div class="row my-2">
        <div class="col-md-2"></div>
        <div class="col-md-10">
          @if (btnKeyboard) {
            <p class="m-1"  style="font-size: small;">Barcode / Catalog number / Artist</p>
            <div><input required style="width: 80%;padding: .25em !important;font-size: small !important;" type="text" pInputText [(ngModel)]="artist"/></div>
            <!--<div><span style="font-size: x-small; color:red" *ngIf="!artist">
            code required
          </span></div>-->
          <p><button pButton type="button" class="p-button-raised p-button-sm mt-1" label="Submit" (click)="onSubmit($event)"></button>&nbsp;&nbsp;
          <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="Clear" (click)="onClearEnter($event)"></button>&nbsp;&nbsp;
        </p>
        <p style="margin-bottom: .25rem !important"><span><p-radioButton name="barcat" value="bar" inputId="bar1" [(ngModel)]="selectedBarCatValue"></p-radioButton>&nbsp;
        <label for="bar1" style="margin: 0px;">Barcode</label>&nbsp;&nbsp;
      </span></p>
      <p style="margin-bottom: .25rem !important"><span><p-radioButton name="barcat" value="cat" inputId="bar2" [(ngModel)]="selectedBarCatValue"></p-radioButton>&nbsp;
      <label for="bar2" style="margin: 0px;">Catalog #</label>&nbsp;&nbsp;
    </span></p>
    <p style="margin-bottom: .25rem !important"><span><p-radioButton name="barcat" value="art" inputId="bar3" [(ngModel)]="selectedBarCatValue"></p-radioButton>&nbsp;
    <label for="bar3" style="margin: 0px;">Artist or 'Artist | Title'</label>&nbsp;&nbsp;
  </span>
</p>
}
<!--<ng-container *ngIf="btnScan">
<div class="barcode-scanner-container">
  <barcode-scanner-livestream
    type="['code_128', 'code_39', 'ean_8']"
    (valueChanges)="onScanChanges($event)"
    (started)="onStarted($event)"
  ></barcode-scanner-livestream>
</div>
<div class="" [hidden]="!barCodeValue">
  {{ barCodeValue }}
</div>
</ng-container>-->
</div>
</div>

@if (artistList) {
  <div class="card">
    <p-dataView #dv [value]="discogsArtist" filterBy="year" layout="grid">
      <ng-template let-artist pTemplate="gridItem">
        <div class="p-col-12 p-md-4">
          <div class="product-grid-item card">
            <div class="product-grid-item-top card-header" style="padding: .25rem;" >
              <!--<fa-icon class="fa-regular" title="Add" [icon]="faPlusSquare" (click)="onAddMusic($event, artist)"></fa-icon>-->
              <!--<p-button (click)="onAddMusic($event, artist)" icon="pi pi-plus-circle" label=""></p-button>-->
              <button pbutton type="button" class="addButt" (click)="onAddMusic($event, artist)">
                <img src="../../../assets/plus-iconffff33.svg" style="background-color: #333300; width: 36px; padding: 0px;">
              </button>
            </div>
            <div class="product-grid-item-content">
              <div>
                <img src="{{artist.imageId}}"/>
              </div>
              <div title="Title" class="product-description"><span title="Title"><fa-icon [icon]="faReceipt"></fa-icon>&nbsp;{{artist.title}}</span></div>
              <div title="Year" class="product-description"><span title="Year"><fa-icon [icon]="faCalendar"></fa-icon>&nbsp;{{artist.year}}</span></div>
              <div>&nbsp;<span title="Barcode" class="product-price"><fa-icon [icon]="faBarcode"></fa-icon>&nbsp;{{artist.barcode}}</span></div>
              <div>&nbsp;<span title="CatNo" class="product-price"><fa-icon [icon]="faBarcode"></fa-icon>&nbsp;{{artist.catno}}</span></div>
              <div>&nbsp;<span title="Label" class="product-price"><fa-icon [icon]="faReceipt"></fa-icon>&nbsp;{{artist.label}}</span></div>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
  <div class="row">
    <div class="col-md-12">
      <p-table [value]="discogsArtist" class="mt-10">
        <ng-template pTemplate="body" let-parentData>
          <tr>
            <td>{{ parentData.year }}</td>
            <td>{{ parentData.title }}</td>
            <td>{{ parentData.barcode }}</td>
            <td>{{ parentData.catno }}</td>
            <td>{{ parentData.format }}</td>
            <td>{{ parentData.genre }}</td>
            <td>{{ parentData.label }}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3">
              <h5 class="text-center">No results found</h5>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
}
</ng-template>
</p-dialog>
</div>


<p-toast [breakpoints]="{'920px': {width: '50%', right: '0', left: '0'}}"></p-toast>
<p-confirmPopup size="60vw"></p-confirmPopup>
<div class="row">
    <p-dialog header="Hive Delete" [(visible)]="displayDelete" (onHide)="hideDelete()" [style]="{width: '50vw'}">
            <ng-template pTemplate="header" style="color: #ffff33">
                <div class="row">
                    <div class="col-md-12"><img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" height="40" class="mr-2">Hive Delete</div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
            <div class="row" style="margin-top: .5rem; border: 1px solid #ffff33">
                <div class="col-md-12" style="margin: 3px;">
                    <span>
                        <input pInputText type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Name']" /> 
                    </span>
                </div>
                <div class="col-md-12" style="margin: 3px;">
                    <span>
                        <input pInputText type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Title']" />
                    </span>
                </div>
                <div class="col-md-12" style="margin: 3px;">
                    <span>
                        <input pInputText type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['BarCode']" /> 
                    </span>
                </div>
                <div class="col-md-12" style="margin: 3px;">
                    <span>
                        <input pInputText type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['ImageId']"/>
                    </span>
                </div>
                <div class="col-md-12" style="margin: 3px;">
                    <span>                        
                        <img [src]="updateData['ImageId']" style="width: 50% !important" class="shadow-4" />
                    </span>
                </div>
            </div>
            </ng-template>
            <ng-template pTemplate="footer">
                <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onDelete($event)"><i class="pi pi-trash"></i></button>&nbsp;&nbsp;
            </ng-template>
    </p-dialog>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-5">
            <div class="card">                
                <button pButton (click)="navigateToTarget('hive')" class="myButton">
                    <img class="card-img-top" src="../../../assets/wasp-sting-svgrepo-com-ffff33.svg" height="600px" alt="Hive">
                </button>
            </div>
        </div>
        <div class="col-md-5">
            <div class="card">
                <button pButton (click)="navigateToTarget('mba')" class="myButton">
                    <img class="card-img-top" src="../../../assets/MBAHead500.svg" height="600px" alt="Hive">
                </button>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

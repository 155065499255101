import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';

export const routes: Routes = [
    { path: '', component: SplashScreenComponent },
    { path: 'home', component: HomeComponent },
    { path: 'splash-screen', component: SplashScreenComponent, title: 'Splash Screen' }

];

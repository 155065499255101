export const environment = {
  production: false,
  sourceAppName: 'base template',
  sourceAppVersion: '0.0.0',
  interServerURL: '69.10.32.26', //The IP\DB connection of ISP InterServer
  interServerDB: '\MSSQLSERVER2019',
  //baseUrl: 'https://dot-wh-inventory-management.azurewebsites.net/api/'
  //baseUrl: 'https://whapis-dev.dotfoods.com/inventorymanagement/api/'
  //baseUrl: 'http://localhost:51867/api/',
  baseUrl: 'https://HiveAPI.theia4u.com/api/',
  oidc: {
    clientId: `0oa3zianjhMZZzgNH5d7`,
    issuer: `https://dev-84654205.okta.com/oauth2/default`,
    redirectUri: 'http://localhost:4200/implicit/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: `true`
    }
  }
  };

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment.dev';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import {TreeModule} from 'primeng/tree';
import {TreeNode} from 'primeng/api';
import { lastValueFrom } from 'rxjs';

const consumerKey: string = "wrqeqExAvxGqXfbtFfWx";
const consumerSecret: string = "BQHyEDTJFmrgrUJaecugHIUbxEYHvEdp";


@Injectable({
  providedIn: 'root'
})
export class APIService {
  
  private userName: string = "";

  // API URLs
  private baseUrl: string;
  private serverUrl: string = environment.baseUrl;
  private lonzUrl: string = 'https://tangtech-musicapi.azurewebsites.net/';
  private discogsUrl: string = 'https://api.discogs.com/database/search';

  private interserverUrl: string = "";

  // DB name.
  public lastSearchedSku: string = "";
  public lastSearchedLotCode: string = "";
  

  private dbName = 'IL_DRY-WMS';
  public location = new Subject<string>();

  constructor(private http: HttpClient) {
    this.setDb(this.dbName);
    this.baseUrl = `${this.serverUrl}`;
  }

  /*** GET METHOD ***/
  promisifyHttpGet(url: string) {
    return lastValueFrom(this.http.get<any>(url))
  }

  setDb(dbName: string) {
    this.dbName = dbName;
    this.location.next(dbName);
    this.baseUrl = `${this.serverUrl}`;
  }

  getMusic() {
    const serviceUrl = `${this.serverUrl}v1/hive/getMusic`;
    return this.http.get(serviceUrl);
  }

  getMusicWithBarcode(barCode: any) {
    const serviceUrl = `${this.serverUrl}v1/hive/getMusicWithBarcode/${barCode}`;
    return this.http.get(serviceUrl);
  }

  async getDiscogByTitleArtist(title: string, artist: string){
    let serviceUrl: any;
    serviceUrl = `${this.discogsUrl}?release_title=${title}&artist=${artist}&format=vinyl&key=${consumerKey}&secret=${consumerSecret}`;
    let promise = null;
    promise = this.promisifyHttpGet(serviceUrl);
    return promise.then(res => { console.log(res); return res; });
  }

  postMusic(formData: any){
    var headers= new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
    let url = 'http://localhost:8000/'
    const serviceUrl = `${url}api/post_music`;
    return this.http.post(serviceUrl, formData, {
      headers: headers
    });
  }

  getGenres(){
    const serviceUrl = `${this.lonzUrl}api/Genres`;
    return this.http.get(serviceUrl);
  }

  getArtist(code: string, barcat: string){
    let serviceUrl: any;
    if( barcat == 'bar')
      serviceUrl = `${this.discogsUrl}?barcode=${code}&key=${consumerKey}&secret=${consumerSecret}`;
    else if( barcat == 'cat')
      serviceUrl = `${this.discogsUrl}?catno=${code}&key=${consumerKey}&secret=${consumerSecret}`;
    else if( barcat == 'art'){
      if( code.includes('|') ){
        let ArtistTitle = code.split('|');
        serviceUrl = `${this.discogsUrl}?release_title=${ArtistTitle[1]}&artist=${ArtistTitle[0]}&key=${consumerKey}&secret=${consumerSecret}`;
      }
      else
        serviceUrl = `${this.discogsUrl}?artist=${code}&key=${consumerKey}&secret=${consumerSecret}`;
    }
    return this.http.get(serviceUrl);
  }

  async getArtistMaster(masterDiscogURL: any){
    let promise = null;
    promise = this.promisifyHttpGet(masterDiscogURL);
    return promise.then(res => { console.log(res); return res; });
  }

  getJsonFile() {
    const serviceUrl = `${this.baseUrl}/core/3/worklogs?from=2021-09-13&to=2021-10-08&project=WMS&project=WAM`;
    return this.http.get(serviceUrl);
  }

  getExample(params: any) {
    if (params.ItemNumber === null || params.ItemNumber === undefined) { params.a = ''; }
    if (params.LotCode === null || params.LotCode === undefined) { params.b = ''; }

    const serviceUrl = `${this.baseUrl}/LotManagement/GetLotManagementInfo?a=${params.a}&b=${params.b}`;

    return this.http.get(serviceUrl).toPromise();
  }

  /*** POST METHOD ***/
  promisifyHttpPost(url: string, requestParams: any) {
    return lastValueFrom(this.http.post<any>(url, requestParams))
  }

  saveMusic(data: any) {
    let promise = null;
    promise = this.promisifyHttpPost(`${this.serverUrl}v1/hive/saveAlbum/`, data);
    return promise.then(res => { return res });
  }

  /*** PUT METHOD ***/
  promisifyHttpPut(url: string, requestParams: any) {
    return lastValueFrom(this.http.put<any>(url, requestParams))
  }

  async editMusic(dbName: any, data: any) {
    let promise = null;
    promise = this.promisifyHttpPost(`${this.serverUrl}v1/hive/saveAlbum/`, data);
    return promise.then(res => { return res });
  }

  /*** DELETE METHOD ***/
  promisifyHttpDelete(url: string) {
    return lastValueFrom(this.http.delete<any>(url))
  }

  deleteItem(element: any) {
    let promise = null;
    const serviceUrl = `${this.serverUrl}v1/hive/deleteMusicWithBarcode/${element.barCode}`;
    promise = this.promisifyHttpDelete(serviceUrl);
    return promise.then(res => res).then(response => { return response; });
  }

}

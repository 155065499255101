import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Message, MessageService, ConfirmationService } from 'primeng/api';
import { APIService } from '../../services/api.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faPlusSquare, faCalendarDays,  faPlusCircle, faReceipt, faBarcode, faKeyboard } from '@fortawesome/free-solid-svg-icons';

import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DataViewModule } from 'primeng/dataview'; 
import { TableModule } from 'primeng/table';


interface Genre {
  name: string,
  code: number
}

interface jsonDiscogs {
  pagination: {page: number, pages: number, per_page: number, items: number},
  results: any[];
}

@Component({
  selector: 'app-bar-eye',
  standalone: true,
  imports: [FormsModule, FontAwesomeModule, TableModule, DataViewModule, RadioButtonModule, DialogModule,ConfirmPopupModule],
  templateUrl: './bar-eye.component.html',
  styleUrls: ['./bar-eye.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class BarEyeComponent implements OnInit {

  displayScan: boolean = false;
  discogsArtist: any[] = [];;
  comments: string = "";
  artist: string = "";
  album: string = "";
  faKeyboard = faKeyboard;
  faBarcode = faBarcode;
  faReceipt = faReceipt;
  faPlusSquare = faPlusSquare;
  faPlusCircle = faPlusCircle;
  faCalendar = faCalendarDays;
  barCodeValue: string = '';
  btnScan: boolean = false;
  artistList: boolean = false;
  btnKeyboard: boolean = false;
  selectedBarCatValue: string = '';

  constructor(private apiService: APIService, private messageService: MessageService, private confirmationService: ConfirmationService) { 
    //this.genres = [
    //  {name: 'Electronic', code: '1'},
    //  {name: 'Metal', code: '2'},
    //  {name: 'Rock', code: '3'},
    //  {name: 'Other', code: '4'}
    //];
    //  this.subgenres = [
    //    {name: 'aggro', code: '1'},
    //    {name: 'dark', code: '2'},
    //    {name: 'industrial', code: '3'}
    //];
  }

  ngAfterViewInit() {
    //this.barcodeScanner.start();
  }

  ngOnInit(): void {
    this.displayScan = false;
    this.artist = "";
    this.album = "";
    this.comments = "";
    this.barCodeValue = "";
  }

  showScanDialog() {
    this.displayScan = true;
    this.btnScan = true;
    this.btnKeyboard = false;
    this.artist = "";
    this.discogsArtist = [];
    this.barCodeValue = "";
    this.selectedBarCatValue = 'bar';
  }

  onClearEnter(evt: any){
    console.log("onScan");
    this.artist = "";
    this.discogsArtist = [];
    this.barCodeValue = "";
    this.artistList = false;
  }

  confirmSave(evt: any, addArtist: any) {
    this.confirmationService.confirm({
        target: evt.target,
        message: 'Are you sure you want to save?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.apiService.saveMusic(addArtist)
                .then(response => {
                  console.log(response);
                  
                }).catch((error: any) => {
                  console.log(error);
                }
            );
            this.messageService.add({severity:'info', summary:'Confirmed', detail:'You have accepted'});
        },
        reject: () => {
            this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
        }
    });
  }

  async onAddMusic(evt: any, addArtist: any){
    console.log("onAddMusic " + JSON.stringify(addArtist));
    if( addArtist.masterDiscog === "" ){
      this.messageService.add({severity: 'error', sticky: false, summary: 'get Artist master record' , detail: 'Master record for Discog artist missing'});      
      return;
    }
    this.confirmationService.confirm({
        target: evt.target,
        message: 'Are you sure you want to Add?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.apiService.getArtistMaster(addArtist.masterDiscog).then(response => {
            console.log("getArtistMaster ", response);
            addArtist.name = (response.artists.length > 0 ? response.artists[0].name : "");
            addArtist.title = (response.title != '' ? response.title : addArtist.title);
      
            this.apiService.getMusicWithBarcode(this.artist)
              .subscribe( (data: any) => {
                console.log("getMusicWithBarcode ", JSON.stringify(data));
                if( data.length > 0 ){
                  this.messageService.add({severity: 'info', summary: 'Add', detail: 'You own '+ addArtist.name + ' / ' + addArtist.title});
                }
                else if( data.length == 0 ) {
                  this.messageService.add({severity: 'info', summary: 'Add', detail: 'You added '+ addArtist.name + ' / ' + addArtist.title});
                  this.confirmSave(evt, addArtist);
                }
              },
              error => {
                this.messageService.add({severity: 'error', sticky: false, summary: 'get Db Artist Error' , detail: error.message});
              }
            );
            
          }).catch((error: any) => {
            console.log("Error :", error);
            this.messageService.add({severity: 'error', sticky: false, summary: 'get Artist master record' , detail: 'Could not find Discog artist'});
          });
        },
        reject: () => {
            this.messageService.add({severity:'error', summary:'Rejected', detail:'You have rejected'});
        }
    })
    

    console.log("test");
  }

  onSubmit(evt: any){
    console.log("onSubmit" + this.artist);
    this.artistList = true;
    if( this.artist ){
      this.getArtist(this.artist);  
    }
    else{
      this.messageService.add({severity: 'error', sticky: false, summary: 'Code can not be empty' , detail: ''});
    }
  }

  onScanChanges(result: any): void{
    console.log("onScanChanges start " + result.codeResult.code);
    this.barCodeValue = result.codeResult.code;
  }

  onStarted(evt: any) {
    console.log('started ', evt);
  }

  onScan(evt: any){
    console.log("onScan");
    //this.barcodeScanner.start();
    this.btnScan = true;
  }

  onKeyboard(evt: any){
    console.log("onKeyboard");
    this.btnKeyboard = !this.btnKeyboard;
  }

  getArtist(code: string){
    this.apiService.getArtist(code, this.selectedBarCatValue)
      .subscribe( (data: any) => {
        console.log(JSON.stringify(data));
        if( data.results.length > 0){
          this.discogsArtist = [];
          for( let i = 0; i < data.results.length; i++){
            this.discogsArtist.push({'dId': data.results[i].id,
                                'year': data.results[i].year, 
                                'releaseDate': data.results[i].year, 
                                'title': data.results[i].title, 
                                'imageId': data.results[i].thumb, 
                                'owner': 'ron',
                                //'genre': (data.results[i].genre.length) ? data.results[i].genre[0] : "",
                                'genre': (data.results[i].style.length) ? data.results[i].style[0] : "",
                                'catno': data.results[i].catno,
                                'barcode': (data.results[i].barcode.length) ? data.results[i].barcode[0] : "",
                                'name': "",
                                'rating': '0',
                                'label': (data.results[i].label.length) ? data.results[i].label[0] : "",
                                'format': (data.results[i].format.length) ? data.results[i].format[0] : "",
                                'masterDiscog': (data.results[i].master_url != null)? data.results[i].master_url : ""});
          }
        }
        else{
          this.messageService.add({severity: 'info', sticky: false, summary: 'Discog Artist not found' , detail: 'No match '+code});  
        }
      },
      error => {
        this.messageService.add({severity: 'error', sticky: false, summary: 'get Artist JSON Error' , detail: error.message});
      }
    );
    console.log("test");
  }
  
  onClose(event: any){
    this.artist = '';
    this.album = '';
    this.btnScan = false;
    this.btnKeyboard = false;
    this.discogsArtist = [];
    this.artistList = false;
    console.log("pDialogHide");
  }

  onClear() {
    console.log("onClear");
  }

  onProgress(event: any){
    console.log("in progress");
  }

  onRemove(event: any) {
    if( event && event.file ){
      this.messageService.add({severity: 'info', summary: 'Remove', detail: 'File removed '+event.file.name});
      //const idx = this.uploadedFiles.indexOf(x => x.name === event.file.name)
      //console.log("index = " + idx);
      console.log("file removed");
    }
  }

  onError(event: any) {
    console.log(event.error.status);
    if( event.error) 
        this.messageService.add({severity: 'error', summary: 'Error '+event.error.status, detail: event.error.message});
  }

}


